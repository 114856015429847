table p {
    margin: 0 0 5px;
}
#main-header {
    border-top: 5px solid $color-primary;
    background: #fff;
}
#main-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    @media screen and (max-width: 576px) {
        flex-direction: column;
    }
}
#main-logo {
    width: 230px;
}
#header-contact {
    @media screen and (max-width: 576px) {
        width: 100%;
        margin: 30px 0 0;
    }
    .crafty-block-list {
        display: flex;
    }
    .crafty-block-list-item {
        position: relative;
        padding: 0 20px 0 35px;
        border-right: 1px solid #ddd;
        margin: 0 0 0 20px;
        @media screen and (max-width: 576px) {
            padding-left: 0;
            padding: 0 10px;
            flex: 1;
            margin: 0;
        }
        .crafty-block-list-image {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -15px;
            img {
                height: 20px;
            }
            @media screen and (max-width: 576px) {
                display: none;
            }
        }
        .crafty-block-list-title {
            font-size: 0.8em;
            line-height: 1.1em;
            color: #808080;
            margin: 0;
            padding: 0;
        }
        .crafty-block-list-description {
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }
        &:last-child {
            padding-right: 0;
            border-right: none;
        }
        @media screen and (max-width: 768px) {
            font-size: 0.9rem;
        }
    }
}
#main-nav {
    background: $color-primary;
    color: #fff;

    a {
        color: #fff;
    }
}
#main-nav {
    flex:1;
    color: #fff;
    margin: 0;
	text-align:left;
    position: relative;
    font-size: 0;
    font-family: $font-family-heading;
}
#main-nav ul.menu {
	list-style: none;
	padding: 0;
    margin: 0 0 0 -15px;
    font-weight: 500;
    font-size: 1rem;

    @media screen and (max-width: 768px) {
        font-size: 0.9rem;
    }
}
#main-nav ul.menu > li {
    display: inline-block;
}
#main-nav ul.menu > li > a {
    color: #fff;
	display: block;
	line-height: 50px;
	padding: 0 15px;
    white-space: nowrap;
}

#main-nav ul.menu > li > a:hover {
    color: #fff;
    text-decoration: none;
}
.main-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-nav-subnav {
	overflow: hidden;
	max-height: 0;
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 15px;
	text-align: left;
	line-height: 1.6em;
	color: #fff;
	text-transform: none;
	margin-top: -5px;
	padding: 0;
	margin: 0;
    transition: max-height 0.4s;
    z-index: 9999;
    width: 280px;
    font-family: $font-family-general;
}
#main-nav .menu > li:hover > .main-nav-subnav {
	max-height: 600px;
}
.main-nav-subnav li {
	display: block;
    clear: both;
    padding: 0;
    margin: 0;
}
.main-nav-subnav a {
    padding: 10px 10px 5px;
    display: block;
    position: relative;
    color: #fff;
    background: $color-primary;
    border-bottom: 1px solid #083b83;
}
.main-nav-subnav a:hover {
    text-decoration: none;
    background: #000;
}
#lang-nav,
#lang-nav-mobile {
    font-size: 0.8rem;
    display: block;
    padding: 13px 0 0;
    a {
        display: inline-block;
        color: #fff;
        text-transform: uppercase;
        padding: 0 8px;
        line-height: 16px;
        height: 12px;
        border-left: 1px solid #fff;

        &:first-child {
            border:none;
        }
        &:last-child {
            padding-right: 0;
        }
    }
}

/** MOBILE NAV **/
#main-nav .mobile-toggle {
	display: none;
	width: 100%;
    font-size: 30px;

    @media screen and (max-width: 576px) {
        font-size: 18px;
    }
}
#main-nav #mobile-toggle {
	display: none;
}
#lang-nav-mobile {
    display: none;
}

@media screen and (max-width: 576px) {
    .main-nav-subnav,
    .main-nav-down-arrow {
        display: none;
    }
	#main-nav {
        position: absolute;
        top: 5px;
        left: 15px;
        background: transparent;

        #lang-nav {
            display:none;
        }
    }
    #lang-nav-mobile {
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        a {
            color: #666;
            border-color: #ddd;
        }
    }
	#main-nav .mobile-toggle {
		text-align: center;
		color:$color-primary;
		display: block;
		position: relative;
        border-radius: 10px;
		margin: 5px 0 0 0;
        padding: 10px 8px 3px;
        z-index: 9999;
        background: #fff;
	}
	#main-nav .main-nav-container {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: -100%;
        height: 100%;
        width: 70%;
        z-index: 9998;
        background: $color-primary;
        transition: left 0.4s ease-in-out;

        #site-nav {
            width: 100%;
        }

        ul.menu {
            display: flex;
            flex-direction: column;
            margin: 60px 0 0 0;
            width: 100%;

            li {
                width: 100%;
            }
            a {
                display: block;
                width: 100%;
                border-bottom: 1px solid rgba(255,255,255,0.4);
                line-height: 36px;
                padding-left: 16px;
            }
        }
	}
	#mobile-toggle:checked ~ .content-container .main-nav-container  {
		left:0;
		border-bottom: 1px solid #ddd;
    }
    .mobile-toggle {
        span {
            display: inline;
        }
        span, small {
            font-size: 1em;
        }
        small {
            display: none;
        }
    }
    .mobile-toggle.opening {
        span {
            display: none;
        }
        small {
            display: inline;
        }
    }

    .main-nav-about {
        ul.main-nav-subnav {
            max-height: 600px;
            position: relative;
            top: 0;
            display: block;
            font-size: 0.9em;

            a {
                line-height: 20px !important;
                &:before {
                    content: "-";
                }
            }
        }
    }

    .main-nav-training {
        ul.main-nav-subnav {
            max-height: 600px;
            position: relative;
            top: 0;
            display: block;
            font-size: 0.9em;

            a {
                line-height: 20px !important;
                &:before {
                    content: "-";
                }
            }
        }
    }
}

.content-container {
    max-width:1100px;
    margin:0 auto;
    padding:0 40px;
    width:100%
}
@media screen and (max-width:768px){
    .content-container{
        padding:0 20px
    }
}

.content-section-header-group {
    margin: 0 0 30px 0;
    h1 {
        color: $color-primary;
        padding: 0;
        margin: 0;
        font-size: 2.4em;
        line-height: 1.3em;
        @media screen and (max-width: 576px) {
            font-size: 1.8em;
        }
    }
    h2 {
        color: $gray-color;
        font-size: 1em;
        line-height: 1.3em;
        font-weight: 200;
        padding: 0;
        margin: 0;
    }
}
.secondary-heading {
    h1 {
        color: #222;
        text-align: center;
    }
    h2 {
        text-align: center;
    }
}
.page-content-header {
    margin-top: 40px;
}

.btn-rounded {
    border-radius: 100px;
    padding-left: 15px;
    padding-right: 15px;
}
.btn-rounded.btn-sm {
    border-radius: 100px;
    padding-left: 10px;
    padding-right: 10px;
}
.btn-outline {
    border: 2px solid $color-primary;
}
.btn-outline-light {
    border-width: 2px;
}
.btn-outline-light:hover {
  color: #fff;
  background-color: $color-primary;
}
.btn {
    transition: all 0.4s;
    img {
        width: 18px;
        margin: 0;
    }
}

#main-footer {
    margin: 60px 0 0 0;
    padding: 50px 0 0;
    background: $color-primary;
    color: #fff;
    font-size: 0.8em;
    line-height: 1.4em;
    font-weight: 200;
    a{
        color: #fff;
    }

    @media screen and (max-width: 576px) {
        margin: 40px 0 0 0;
        padding: 40px 0 20px;
        .row > div {
            margin-bottom: 20px;
        }
    }
}
.footer-intro-wrapper {
}
.footer-intro-buttons {
    .btn {
        font-size: 0.9em;
        margin: 0 5px 5px  0 ;
    }
}
.footer-intro-logo {
    max-width: 200px;
}
.footer-intro-desc {
    margin: 20px 0;
    max-width: 360px;
}
#footer-copy {
    background: #103E73;
    color: #fff;
    text-align: center;
    font-size: 0.7em;
    line-height: 1.5em;
    font-weight: 200;
    padding: 10px 0 8px;

    span {
        opacity: 0.7;
    }
    a {
        color: #fff;
    }
}
.header-background-title {
    padding: 150px 0;
    text-align: center;
    color: #fff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;
}
.header-background-large {
    padding: 35vw 0 0;
    text-align: center;
    color: #fff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;

}

.header-background-video {
    overflow: hidden;
    height: 50vh;
    background: #222;
    position: relative;

    @media screen and (max-width: 768px) {
        height: 50vw;
    }

    @media screen and (min-width: 767px) {
        .video-js {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            height: auto;
            @media screen and (max-width: 768px) {
                top: 0;
                transform: translateX(-50%);
                left: 50%;
                height: 100%;
                width: auto;
            }
        }
    }

    video {
        width: 100%;
        height: auto;
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        z-index: 2;
        font-size: 3em;
        text-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    }
    /*
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(to bottom, rgba(0,0,0,0) , rgba(0,0,0,0.5));
    }
    */
}

.breadcrumb {
    padding: 3px 0 2px;
    background: #f1f1f1;
    font-size: 0.8em;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 40px;
}
.content-search {
    list-style: none;
    padding: 0;
    margin: 0;
    .content-search-item {
        display: block;
        margin: -1px 0 0 0;
        padding: 10px;
        border: 1px solid #ddd;
    }
}


.the-link {
    color: $color-primary;
    text-decoration: underline;
}

.search-form {
    position: relative;
    margin: 0;
    padding: 0;
    input[type=search] {
        border-radius: 30px;
        border: 1px solid #ddd;
        line-height: 26px;
        padding: 6px 30px 3px 15px;
        display: block;
        margin: 0;
        width: 100%;
        height: 36px;

        &:focus, &:active {
            outline: none;
            border-color: $color-secondary;
        }
    }
    input[type=submit] {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 36px;
        height: 36px;
        border: none;
        background: transparent url(/theme/static/images/search.svg)  no-repeat center center;
        background-size: 16px;
        text-indent: -9999px;
        overflow: hidden;
    }
}
