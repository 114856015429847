.services-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    .services-list-item {
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        transition: background-color 0.4s;

        &:hover {
            background: #f1f1f1;
        }
    }
    .services-list-item-image img {
        width: 100%;
    }
    .services-list-item-content {
        padding: 15px 20px;
    }

    a {
        text-decoration: none;
    }


    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1,1fr);
        font-size: 0.9em;
    }
}


.service-icon-list {
    .crafty-block-list {
        display: grid;
        grid-template-columns: repeat(5,1fr);
        gap: 0 20px;
        margin: 20px 0;
        text-align: center;

        .crafty-block-list-image {
            background: #F2F2F2;
            margin: 0 0 5px;
            width: 100%;
            padding: 60% 0 0 0;
            position: relative;
            overflow: hidden;
        }
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            height: 100%;
            transform: translateX(-50%);
            width: auto;
        }

        h3 {
            font-size: 0.8em;
            line-height: 1.4em;
            padding: 0;
            margin: 0;
        }


        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(3,1fr);
        }
        @media screen and (max-width: 576px) {
            grid-template-columns: repeat(2,1fr);
        }
    }
}

.service-realibility {
    .crafty-block-list {
        margin: 0 0 40px;
        display: grid;
        grid-template-columns: repeat(4,1fr);
    }
    .crafty-block-list-item {
        max-width: 350px;
        max-height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        padding: 20px 0 10px;
    }

    .crafty-block-list-description {
        display: none;
    }

    .crafty-block-list-item:nth-child(1){
        z-index: 4;
    }
    .crafty-block-list-item:after {
        content: "";
        position: absolute;
        right: -8px;
        top: 50%;
        margin-top: -10px;

        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;

        border-left: 14px solid $color-primary;
        z-index: 3;
    }
    .crafty-block-list-item:nth-child(2){
        background: $color-pale;
        z-index: 3;
    }
    .crafty-block-list-item:nth-child(3){
        z-index: 2;
    }
    .crafty-block-list-item:nth-child(4){
        background: $color-pale;
        z-index: 1;
    }
    .crafty-block-list-item:last-child:after {
        display: none;
    }

    .crafty-block-list-image {
        width: 70px;
        height: 80px;
        line-height: 80px;
        margin: 0 auto;
        text-align: center;
    }
    .crafty-block-list-image img {
        max-width: 100%;
        max-height: 100%;
    }
    .crafty-block-list-title {
        text-align: center;
        text-transform: uppercase;
        padding: 10px 0 0;
        color: $color-primary;
        max-width: 200px;
        font-weight: bold;

        font-size: 14px;
        line-height: 1.3em;
        a {
            color: $color-primary;
        }

        @media screen and (max-width: 576px) {
            font-size: 13px;
            .crafty-block-list-title {
                font-size: 1em;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .crafty-block-list {
            grid-template-columns: repeat(2,1fr);
            grid-gap: 10px;
        }
        .crafty-block-list-item {
            padding: 0 0 10px;
        }

        .crafty-block-list-item:nth-child(2){
            z-index: 3;
        }
        .crafty-block-list-item:after {
            right: -18px;
        }
        .crafty-block-list-item:nth-child(2):after {
            content: "";
            position: absolute;
            top: auto;
            left: 50%;
            bottom: -18px;
            margin-left: -10px;

            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-top: 14px solid $color-primary;
            z-index: 3;
        }
        .crafty-block-list-item:nth-child(3){
            order: 4;
            z-index: 2;
        }
        .crafty-block-list-item:nth-child(3):after {
            content: "";
            position: absolute;
            top: 50%;
            left: -18px;
            margin-top: -10px;

            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 0;
            border-right: 14px solid $color-primary;
            z-index: 2;
        }
        .crafty-block-list-item:nth-child(4){
            order:3;
            z-index: 1;
        }
    }
}


.assessment-list {
    margin: 40px 0;
    .crafty-block-list-item {
        background: #18468e;
        color: #fff;
        margin: 0;
        padding: 40px 0;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            padding: 20px 0;
        }

        &:nth-child(even) {
            background: #0d3875;
        }

        &>div {
            max-width:1100px;
            margin:0 auto;
            padding:0 40px;
            width:100%;
            display: flex;
            flex-direction: row-reverse;
            @media screen and (max-width:768px){
                padding:0 20px;
                flex-direction: column !important;
            }
        }

        .crafty-block-list-image {
            width: 40%;
            padding: 0 0 0 30px;

            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 0 0 10px !important;
            }
        }

        .crafty-block-list-content {
            flex:1;
        }
        &:nth-child(even) {
            background: #0d3875;
            &>div {
                flex-direction: row;
            }
            .crafty-block-list-image {
                padding: 0 30px 0 0;
            }
        }
    }
}

.sustain-counter {
    margin: 20px 0;
    background: #f6f6f6;
    padding: 40px 20px 20px;
    border-radius: 10px;
    .crafty-block-list {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 10px;

        @media screen and (max-width:576px){
            grid-template-columns: repeat(1,1fr);
        }
    }
    .crafty-block-list-item {
        text-align: center;
        font-family: $font-family-heading;
        .crafty-block-list-image {
            width: 120px;
            max-width: 100%;
            margin: 0 auto 10px;
        }
        .crafty-block-list-title {
            font-size: 0.9em;
        }
        .crafty-block-list-description {
            font-size: 1.6em;
            font-weight: bold;
            color: $color-primary;
        }
    }
}
