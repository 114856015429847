.products-list-categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    font-size: 0.9em;
    margin: 20px 0;

    a small {
        color: $color-primary;
    }

    .products-list-category-content {
        h2 {
            margin: 10px 0 5px 0;
            font-size: 1.5em;
            font-weight: bold;
        }
        p {
            margin: 5px 0;
        }
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
        font-size: 0.7em;
        line-height: 1.2em;
        h2 {
          line-height: 1.2em;
        }
    }
}
.product-hilights {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    font-size: 0.85em;
    line-height: 1.3em;
    margin: 0 0 40px;


    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 2px;
    }

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
        grid-gap: 20px;
    }

    .product-hilight {
        background: #f1f1f1;
        border: 1px solid #ddd;
    }

    video {
        width: 100%;
        height: auto;
    }

    .product-hilight-detail {
        padding: 5px 10px;
        display: block;
    }

    a {
        text-decoration: none;
    }
}
.industrial-products {
    background: #00A4E3;
    display: grid;
    grid-template-columns: 200px 1fr;
    margin: 0 0 5px 0;
    padding: 5px;
    border-radius: 2px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 160px 1fr;
    }

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
        grid-gap: 20px;
    }

    .industrial-products-header {
        padding: 0 5px;
        color: #fff;

        small {
            display: block;
            font-size: 0.8em;
        }
        strong {
            display: block;
            font-size: 1.6em;
            font-weight: normal;
        }
    }
    .industrial-products-list {
        background: #fff;
        border-radius: 2px;

        .industrial-products-item {
            display: block;
            padding: 15px 40px 10px 10px;
            border-bottom: 1px solid #ddd;
            font-size: 1em;
            line-height: 1.4em;
            background: transparent url(/theme/static/images/arrow-right.svg) no-repeat right 10px center;
            text-decoration: none;
            transition: background-color 0.3s;

            strong {
                display: block;
                font-size: 1.3em;
                margin-bottom: 5px;
            }
            small {
                font-size: 0.8em;
                line-height: 1.3em;
                color: #666;
            }

            @media screen and (max-width: 576px) {
                font-size: 0.9em;

                small {
                    line-height: 1.1em;
                }
            }

            &:hover {
                background-color: #f6f6f6;
            }
        }
        .industrial-products-item:last-child {
            border-bottom: none;
        }
    }
}
.industrial-products-flagship {
    background-color: #015CA8;
}
.industrial-products-premium {
    background-color: #006691;
}
.product-mobil-section {
    border-bottom: 1px solid #eee;
    padding: 0;
    margin: 0px 0 30px 0;
}
.product-mobil-content-detail {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    @media screen and (max-width: 576px) {
        flex-direction: column-reverse;
        text-align: center;
        padding-bottom: 0;
    }
    .product-mobil-heading {
        padding: 25px 0 0;
        .content-section-header-group {
            display: flex;
            flex-direction: column-reverse;

            h1 {
                font-size: 1.6em;
                font-weight: bold;
                color: $color-primary;
                @media screen and (max-width: 576px) {
                    font-size: 1.1em;
                    line-height: 1.5em;
                }
            }
            h2 {
                font-size: 0.9em;
                color: #222;
            }
        }
    }
    .product-mobil-image {
        max-width: 420px;
        @media screen and (max-width: 576px) {
            display: block;
            margin: 0 auto 10px;
            text-align: center;
            width: 100%;
        }
    }
}
.products-by-application-wrapper {
    margin: 40px 0 ;
}
.products-by-application {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 10px;
    margin: 10px 0;
    font-size: 0.9em;
    a {
        display: block;
        border: 1px solid #ddd;
        box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
        transition: all 0.3s;
        position: relative;
        background-color: #045CBB;
        strong {
            display: block;
            padding: 5px 10px 1px;
            position: absolute;
            background: rgba(0,0,0,0.7);
            bottom: 0;
            left: 0;
            width: 100%;
            color: #fff;
        }

        img{
            filter: grayscale(60%);
            transition: all 0.3s;
            //mix-blend-mode: multiply;
        }

        &:hover {
            text-decoration: none;
            box-shadow: 0 0 3px rgba(0,0,0,0.5);

            img{
                filter: none;
                //mix-blend-mode: normal;
            }
        }
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3,1fr);
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2,1fr);
        font-size: 0.8em;
        line-height: 1.3em;
    }
}

.product-item-header {
    display: flex;
    margin: 0 0 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}
.product-item-description {
    font-size: 1.1em;
    line-height: 1.4em;;
    flex:1;
    margin: 0 40px 0 0;
}
.product-item-buttons {
    width: 280px;
}
.product-type {
    display: inline-block;
    padding: 6px 10px 3px 10px;
    border-radius: 3px;
    background: #00A4E3;
    color: #fff;
    font-size: 14px;
    line-height: 1.5em;
    margin: 0 0 10px;
}
.product-type-flagship {
    background-color: #015CA8;
}
.product-type-premium {
    background-color: #006691;
}

.type-of-fules {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
    display: flex;

    > li {
        display: block;
        flex: 1;
        border: 5px solid $color-primary;
        border-radius: 5px;
        overflow: hidden;
        background: $color-primary;
        color: #fff;

        &:last-child {
            margin-left: 40px;
            border: 5px solid $color-secondary;
            background: $color-secondary;


            @media screen and (max-width: 768px) {
                margin-left: 0;
                margin-top: 10px;
            }
        }

        display: flex;
        .type-of-fule-title {
            padding: 3px 10px;
            margin: 0;
            width: 160px;
        }
        .type-of-fule-content {
            background: #fff;
            border-radius: 3px;
            height: 100%;
            color: #222;
            flex: 1;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                    border-bottom: 1px solid #f1f1f1;
                    margin: 0 0 1px;
                    padding: 5px 10px 2px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.product-skf-section,
.product-brand-section {
    padding: 18px 0 0;
    border-bottom: 1px solid #f1f1f1;
}
.product-brand-section {
    padding: 18px 0;
    .content-section-header-group {
        margin: 0;
    }
}
.product-skf-content-detail,
.product-brand-content-detail {
    display: flex;
    justify-content: space-between;
    justify-items: center;


    @media screen and (max-width: 567px) {
        flex-direction: column-reverse;
        text-align: center;
    }

    .content-section-header-group {
        display: flex;
        flex-direction: column-reverse;
        h1 {
            font-size: 1.6em;
            font-weight: 700;
            color: #174e9c;
            @media screen and (max-width: 567px) {
              font-size: 1.3em;
            }
        }
        h2 {
            font-size: .9em;
            color: #222;
        }
    }
    .product-skf-heading {
        padding: 15px 0 0;
    }
    .product-skf-image,
    .product-brand-image {
        width: 150px;
        @media screen and (max-width: 567px) {
            margin: 5px auto 10px;
            text-align: center;
        }
    }
    .product-brand-image {
        width: 240px;
        margin: 0;
        text-align: right;
        img {
            max-height: 60px;
        }
        @media screen and (max-width: 567px) {
          margin: 5px auto 10px;
          text-align: center;
        }
    }
}

.skf-product-list,
.category-product-list {
    list-style: none;
    padding: 0;
    margin: 0 0 40px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3,1fr);
    text-align: center;
    font-weight: bold;
    color: $color-primary;

    &.skf-product-number-small {
        grid-template-columns: repeat(2,1fr);
    }

    &.skf-product-number-large {
        grid-template-columns: repeat(4,1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr) !important;
        gap: 15px;
    }

    .products-list-item {
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        background: #fff;
        transition: all 0.3s;
        &:hover {
            background: #f1f1f1;
            box-shadow: 1px 1px 8px rgba(0,0,0,0.3);
        }
        a {
            color: $color-primary;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .products-list-item-content {
        padding: 6px 0 3px;
    }
}
