.events-list {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2,1fr);
    .events-list-item {
        position: relative;

        .content-list-item-content {
            position: absolute;
            bottom: 0;
            padding: 5px 15px;
            left: 0;
            right: 0;
            color: #fff;
            z-index: 2;
            font-weight: normal;
        }

        &:after {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80px);
            border: 5px solid #fff;
            pointer-events: none;
        }
    }

    @media screen and (max-width: 567px) {
        grid-template-columns: repeat(1,1fr);
    }
}


.display-images {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3,1fr);


    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2,1fr);
    }
    @media screen and (max-width: 567px) {
        grid-template-columns: repeat(1,1fr);
    }
}
