.industries-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 30px;

    a {
        text-decoration: none;
    }

    .content-list-item {
        box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
        background: $color-primary;
        transition: all 0.3s;

        &:hover {
            box-shadow: 1px 1px 10px rgba(0,0,0,0.8);

        }
    }

    .content-list-item-content {
        color: #fff;
        display: block;
        padding: 15px;
        font-weight: 200;

        strong {
            display: block;
            font-size: 1.3em;
            margin: 0 0 5px;
        }
    }

    @media screen and (max-width: 1024px) {
        gap: 10px;
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
        gap: 10px;
    }
}


.industrial-item-header {
    display: flex;
    margin: 40px 0 30px;
    box-shadow: 1px 1px 6px rgba(0,0,0,0.5);

    .industrial-item-image {
        width: 60%;
        background: #000;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
    .industrial-item-title {
        flex: 1;
        background: $color-primary;
        color: #fff;
        padding: 20px 30px;
        @media screen and (max-width: 567px) {
            font-size: 0.9em;
        }
        h1 {
            font-size: 2em;
            line-height: 1.5em;
            font-weight: bold;
            @media screen and (max-width: 567px) {
                font-size: 1.6em;
            }
        }
        h3 {
            font-size: 1.1em;
            line-height: 1.4em;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.related-header {
    margin: 40px 0 0 0;
    h2 {
        color: $color-primary;
        padding: 0;
        margin: 0;
        font-size: 2em;
        font-weight: bold;
        line-height: 1.4em;
    }
    h3 {
        color: #424242;
        font-size: 1em;
        line-height: 1.3em;
        font-weight: 200;
        padding: 0;
        margin: 0;
    }
}

.related-services {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4,1fr);
    margin: 10px 0;

    .related-service {
        background: $color-primary;
        color: #fff;
        transition: background-color 0.3s;

        strong {
            color: #fff;
            display: block;
            font-size: 0.8em;
            line-height: 1.3em;
            padding: 10px 10px 5px;
            text-align: center;
        }
        small {
            color: #fff;
            display: block;
            font-size: 0.8em;
            line-height: 1.3em;
            padding: 0 10px 10px;
        }

        &:hover {
           background: #000;
        }
    }

    a {
        text-decoration: none;
    }
    a:hover {

    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3,1fr);
        font-size: 0.9em;
    }
    @media screen and (max-width: 567px) {
        grid-template-columns: repeat(2,1fr);
    }
}
