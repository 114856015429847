.knowledge-list {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(2,1fr);

    .knowledge-list-item {
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        transition: 0.4s;
        .knowledge-list-item-content {
            padding: 10px 15px;
            border-top: 1px solid #f1f1f1;
            color: $color-primary;
            font-size: 0.9em;
            line-height: 1.5em;
        }

        &:hover {
            background: #f1f1f1;
        }
        a:hover {
            text-decoration: none;
        }
    }

    @media screen and (max-width: 1024px) {
        gap: 20px;
    }
    @media screen and (max-width: 567px) {
        grid-template-columns: repeat(1,1fr);
        gap: 15px;
    }
}

.contact-hero-section {
    background: #f1f1f1;
    margin: 40px 0;
    padding: 30px 0;
}
.contact-hero-content {

    hgroup {
        h6 {
            color: #666;
            font-weight: 200;
            margin: 0;
            text-align: center;
        }
        h5 {
            color: $color-primary;
            font-size: 1.8em;
            line-height: 1.3em;
            margin: 0;
            text-align: center;
        }
    }
    .contact-hero-content-detail {
        text-align: center;

        .contact-btn {
            display: inline-block;
            border-radius: 50px;
            line-height: 53px;
            height: 50px;
            padding: 0 20px 0 50px;
            margin: 10px 5px;
            font-weight: bold;
            color: $color-primary;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
            white-space: nowrap;
            font-size: 1.2em;
            text-decoration: none;
            position: relative;
            background-color: #fff;
            img {
                width: 30px;
                padding: 0;
                margin: 0 5px 0 0;
                position: absolute;
                top: 10px;
                left: 10px;
            }

            @media screen and (max-width: 576px) {
                line-height: 38px;
                height: 36px;
                padding: 0 10px 0 40px;
                margin: 5px 2px;
                font-size: 0.9em;

                img {
                    width: 26px;
                    top: 5px;
                    left: 5px;
                }
            }
        }
    }
}
