.about-banner  {
    margin: 0;
    img {
        width: 100%;
    }
}


.contact-box {
    display: flex;
    background: #fff;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
    margin: 40px 0;

    a {
        color: #fff;
    }

    .contact-box-form {
        flex: 1;
        padding: 30px;
    }
    .contact-box-info {
        min-height: 100%;
        background: $color-primary;
        width: 350px;
        padding: 30px;
        color: #fff;
        line-height: 1.8em;
    }

    @media screen and (max-width: 768px) {
        margin: 30px 0;
        flex-direction: column;
        .contact-box-info {
            width: 100%;
        }
    }
}

.contact-page-list {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 40px;
    text-align: center;
    font-size: 16px;
    margin: 40px 0;

    a {
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
        padding: 20px 0;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s;
    }
    a:hover {
        background: $color-pale;
    }

    img {
        width: 60px;
    }

    small {
        margin: 8px 0 0 0;
        display: block;
        color: #888;
    }
    span {
        display: block;
    }


    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
        margin: 20px 0;
    }

}

.maps-list-wrapper {
    .crafty-block-list {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 40px;

        @media screen and (max-width: 768px) {
            grid-gap: 20px;
        }
        @media screen and (max-width: 567px) {
            grid-template-columns: repeat(1,1fr);
            grid-gap: 20px;
        }
    }
}

.about-award-items {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;


    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 30px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1,1fr);
        grid-gap: 20px;
    }

    .about-award-item {
        border: 1px solid #eee;

        strong {
            margin: 10px 0 0;
            display: block;
            padding: 5px 10px;
        }
        span {
            display: block;
            font-size: 0.8em;
            line-height: 1.5em;
            padding: 5px 10px;
        }
    }
}


.csr-events-list {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3,1fr);

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2,1fr);
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
    }

    .csr-events-list-item {
        position: relative;

        &>strong {
            position: absolute;
            bottom: 0;
            padding: 5px 15px;
            left: 0;
            right: 0;
            color: #fff;
            z-index: 2;
            font-weight: normal;
        }

        &:after {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80px);
            border: 5px solid #fff;
            pointer-events: none;
        }
    }
}


.mobil-circle-wrapper {
    .crafty-block-list {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        margin: 40px 0 20px;
        gap: 20px 50px;
        .crafty-block-list-item {

            .crafty-block-list-image {
                width: 100%;
            }
            .crafty-block-list-title ,
            .crafty-block-list-description {
                display: none;
            }
        }


        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(3,1fr);
            grid-gap: 30px;
        }

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2,1fr);
            grid-gap: 50px;
        }

        @media screen and (max-width: 385px) {
            grid-gap: 20px;
        }
    }
}
