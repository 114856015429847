@font-face {
    font-family: 'WF Poppins';
    src: url('../static/fonts/poppins/poppins-italic-webfont.woff2') format('woff2'),
         url('../static/fonts/poppins/poppins-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'WF Poppins';
    src: url('../static/fonts/poppins/poppins-light-webfont.woff2') format('woff2'),
         url('../static/fonts/poppins/poppins-light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'WF Poppins';
    src: url('../static/fonts/poppins/poppins-lightitalic-webfont.woff2') format('woff2'),
         url('../static/fonts/poppins/poppins-lightitalic-webfont.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'WF Poppins';
    src: url('../static/fonts/poppins/poppins-medium-webfont.woff2') format('woff2'),
         url('../static/fonts/poppins/poppins-medium-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'WF Poppins';
    src: url('../static/fonts/poppins/poppins-mediumitalic-webfont.woff2') format('woff2'),
         url('../static/fonts/poppins/poppins-mediumitalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'WF Poppins';
    src: url('../static/fonts/poppins/poppins-regular-webfont.woff2') format('woff2'),
         url('../static/fonts/poppins/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Prompt WF';
    src: url('../static/fonts/prompt/prompt-light-webfont.woff2') format('woff2'),
         url('../static/fonts/prompt/prompt-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Prompt WF';
    src: url('../static/fonts/prompt/prompt-lightitalic-webfont.woff2') format('woff2'),
         url('../static/fonts/prompt/prompt-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Prompt WF';
    src: url('../static/fonts/prompt/prompt-semibold-webfont.woff2') format('woff2'),
         url('../static/fonts/prompt/prompt-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Prompt WF';
    src: url('../static/fonts/prompt/prompt-semibolditalic-webfont.woff2') format('woff2'),
         url('../static/fonts/prompt/prompt-semibolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Prompt WF';
    src: url('../static/fonts/prompt/prompt-extralight-webfont.woff2') format('woff2'),
         url('../static/fonts/prompt/prompt-extralight-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Prompt WF';
    src: url('../static/fonts/prompt/prompt-extralightitalic-webfont.woff2') format('woff2'),
         url('../static/fonts/prompt/prompt-extralightitalic-webfont.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Sarabun WF';
    src: url('../static/fonts/sarabun/sarabun-regular-webfont.woff2') format('woff2'),
         url('../static/fonts/sarabun/sarabun-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Sarabun WF';
    src: url('../static/fonts/sarabun/sarabun-italic-webfont.woff2') format('woff2'),
         url('../static/fonts/sarabun/sarabun-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Sarabun WF';
    src: url('../static/fonts/sarabun/sarabun-semibold-webfont.woff2') format('woff2'),
         url('../static/fonts/sarabun/sarabun-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Sarabun WF';
    src: url('../static/fonts/sarabun/sarabun-semibolditalic-webfont.woff2') format('woff2'),
         url('../static/fonts/sarabun/sarabun-semibolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}
