.crafty-block-slideshow-video {
    overflow: hidden;
    height: calc(100vh - 163px);
    background: #222;
    position: relative;

    @media screen and (max-width: 1310px) {
        height: auto
    }

    @media screen and (min-width: 1309) {
        &>.crafty-block-video-player {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 100%;
            @media screen and (max-width: 1048px) {
                top: 0;
                transform: translateX(-50%);
                left: 50%;
                height: 100%;
                width: auto;
            }
        }
    }

    video {
        width: 100%;
        height: auto;
        @media screen and (max-width: 1048px) {

        }
    }
    /*
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to left, rgba(0,0,0,0) , rgba(0,0,0,0.5));
        background-blend-mode: multiply;
    }
    */
    .crafty-block-slideshow-content {
        position: absolute;
        z-index: 99;
        top: 0;
        left: 10%;
        height: 100%;
        img {
            max-height: 100%;
        }
    }
}
.header-slideshow {
    margin-bottom: 60px;

    @media screen and (max-width: 576px) {
        margin-bottom: 30px;
    }
}

.home-hero-content {
    display: flex;
    .home-hero-content-detail {
        flex: 1;
        margin: 0 40px 0 0;
        font-weight: 200;

        @media screen and (max-width: 576px) {
            margin-right: 0;
        }
    }
    .home-hero-content-image {
        width: 32%;
        text-align: right;
        margin-top: -20px;
        @media screen and (max-width: 576px) {
            width: 100%;
            margin: 0 0 10px;
            text-align: center;
        }
    }
    @media screen and (max-width: 576px) {
        flex-direction: column;
    }
}
.home-hero-content-image-iso {
    margin: 0 0 10px;
    img {
        width: 200px;
    }
}
.home-innovation-section {
    margin: 0;
    background: $color-primary url(/theme/static/images/innovation-hero.jpg) no-repeat center center;
    background-size: cover;
    padding: 50px 0 40px;
    color: #fff;
    h1 {
        font-size: 1.8em;
    }
    a,h1,h2 {
        color: #fff;
    }
    .crafty-block-article-wrapper {
        font-size: 1em;
        font-weight: 200;
        margin: 20px 20px 20px 0;
    }
    .home-innovation-content-detail {
        margin: 0 0 0 55%;
        @media screen and (max-width: 576px) {
            margin: 0;
        }
    }
    .btn-outline {
        border-color: #fff;

        &:hover {
            background: $color-primary;
            color: #fff;
        }
    }
}
.home-mobil-section {
    border-top: 1px solid #eee;
    padding: 10px 0;
    margin: 20px 0 5px 0;
}
/*
.home-mobil-content-detail {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;

    .home-mobil-heading {
        max-width: 170px;
        margin-right: 30px;
    }
    .home-mobil-image {
        max-width: 400px;
        @media screen and (max-width: 576px) {
            display: block;
            margin: 0 auto;
            text-align: center;
            width: 100%;
        }
    }
}
*/
.home-mobil-content-detail {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    @media screen and (max-width: 576px) {
        flex-direction: column-reverse;
        text-align: center;
        padding-bottom: 0;
    }
    .home-mobil-heading {
        padding: 25px 0 0;
        .content-section-header-group {
            display: flex;
            flex-direction: column-reverse;

            h1 {
                font-size: 1.6em;
                font-weight: bold;
                color: #222;
                @media screen and (max-width: 576px) {
                    font-size: 1.1em;
                    line-height: 1.5em;
                }
            }
            h2 {
                font-size: 0.9em;
                color: #222;
            }
        }
    }
    .home-mobil-image {
        max-width: 420px;
        @media screen and (max-width: 576px) {
            display: block;
            margin: 0 auto 10px;
            text-align: center;
            width: 100%;
        }
    }
}

.home-categories-section {
    padding: 50px 0;
    @media screen and (max-width: 576px) {
        padding: 30px 0;
    }
    .content-section-header-group {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 0 10px;
    }
}
.home-product-detail {
    margin: 0 30% 20px 0;
    font-weight: 200;
    @media screen and (max-width: 576px) {
        margin-right: 0;
    }
}
.home-product-categories {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;

    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2,1fr);
        grid-gap: 10px;
        font-size: 0.9em;
    }

    .home-product-category {
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        transition: all 0.4s;

        &:hover {
            box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
        }
    }
    .home-product-category-content {
        display: block;
        padding: 10px 10px 10px 15px;
        strong, span {
            display: block;
            font-size: 1.1em;
            margin: 0 0 5px;
        }
        span {
            display: block;
            font-weight: 200;
            line-height: 1.3em;
            font-size: 0.9em;
        }
    }
    a:hover {
        text-decoration: none;
    }

}
.home-contact-section {
    margin: 70px 0 60px;
    background: $color-primary url(/theme/static/images/contact-bg.jpg) no-repeat top right;
    background-size: cover;
    padding: 50px 0 50px;
    min-height: 25vw;
    color: #fff;
    display: flex;
    align-items: center;
    .content-section-header-group {
        display: flex;
        flex-direction: column-reverse;
        margin: 0;
    }
    h1 {
        font-size: 2em;
        margin: 6px 0;
    }
    h2 {
        font-size: 1.2em;
    }
    a,h1,h2 {
        color: #fff;
    }
    .crafty-block-article-wrapper {
        font-size: 0.9em;
        font-weight: 200;
        margin: 10px 0;
    }
    .home-contact-content-detail {
        margin: 0 45% 0 0;
        @media screen and (max-width: 576px) {
            margin-right: 0;
        }
    }
    .home-contact-detail {
        margin: 0 0 40px;
    }
    .home-contact-contacts {
        .crafty-block-list {
            display: flex;
            flex-direction: row;
        }

        .crafty-block-list-item {
            line-height: 30px;
            height: 33px;
            display: flex;
            justify-items: center;
            border-radius: 100px;
            border: 1px solid #fff;
            padding: 5px 15px;
            margin: 0 20px 0 0;
            .crafty-block-list-title {
                font-size: 0.9em;
            }
            .crafty-block-list-image {
                margin: 2px 10px 0 0;
                line-height: 0;
            }
            img {
                width: 16px;
            }
        }
    }
    .home-contact-buttons {
        .btn {
            font-size:0.9em;
            margin: 0 5px 5px 0;
        }
    }
}
.home-industries-section {
    margin: 0 0 60px;

    @media screen and (max-width: 576px) {
        margin-bottom: 30px;
    }

    .home-industries {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        font-size: 0.9em;
        line-height: 1.3em;
        @media screen and (max-width: 576px) {
            grid-template-columns: repeat(2,1fr);
            grid-gap: 10px;
        }
    }


    .home-industry-item {
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        transition: all 0.4s;
        &:hover {
            box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
        }
    }
    .home-industry-item-detail {
        padding: 10px;
        display: block;
        strong {

        }
        span {
            display: block;
            font-weight: 200;
            font-size: 0.9em;
            color: #888;
        }
    }
    a:hover {
        text-decoration: none;
    }
}
.home-services-section {
    background: #f1f1f1;
    padding: 50px 0;
    margin: 0 0 50px;
    @media screen and (max-width: 576px) {
        padding: 30px 0;
        margin: 0 0 30px;
    }
    a:hover {
        text-decoration: none;
    }

    .home-services {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        @media screen and (max-width: 576px) {
            grid-template-columns: repeat(1,1fr);
            grid-gap: 10px;
        }
    }
    .home-service-item {
        background: #fff;
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
        display: flex;
        transition: all 0.4s;
        &:hover {
            box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
        }
    }
    .home-service-item-image {
        width: 40%;
        margin: 0;
    }
    .home-service-item-detail {
        flex: 1;
        font-size: 0.9em;
        line-height: 1.3em;
        padding: 10px;
        strong {
            color: $color-primary;
            display: block;
            margin: 0 0 5px;
            height: 2.6em;
        }
        span {
            display: block;
            font-size: 0.8em;
            font-weight: 200;
            line-height: 1.5em;
            height: 4.2em;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.home-events-section {
    .content-section-header-group {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 0 10px;
    }
    .home-events {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: 0;
        @media screen and (max-width: 576px) {
            grid-template-columns: 1fr 1fr;
        }

        .home-event-item {
            &:first-child {
                grid-row-start: 1;
                grid-row-end: 3;

                @media screen and (max-width: 576px) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }
        }
    }

    .home-event-item-image {
        strong {
            position: absolute;
            display: block;
            bottom: 0;
            width: 100%;
            padding: 0 10px 10px 20px;
            font-size: 0.8em;
            z-index: 2;
            color: #fff;
            font-weight: 200;
        }
    }
    .home-event-item {
        position: relative;
        &:after {
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 80px);
            border: 5px solid #fff;
            pointer-events: none;
        }
    }
}
